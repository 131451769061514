<template>
  <div>
    <ExperienceListings />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ExperienceListings from "@/view/pages/experience/components/ExperienceListings";

export default {
  components: {
    ExperienceListings
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "ExperienceListings" }]);
  },
};
</script>