<template>
  <div class="row">
    <div class="col-lg-3">
      <ListingsFilter ref="listings-filter" @changed="filterChanged"/>
    </div>
    <div class="col-lg-9">
      <div class="card card-custom card-stretch">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
          <div class="card-title">
            <h3 class="card-label">Deneyimler
              <span class="d-block text-muted pt-2 font-size-sm">Toplam {{totalRows}} adet deneyim bulunmakta</span>
            </h3>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-dark font-weight-bolder" :disabled="createButton.isProcessing" @click="createExperience">
              <div v-if="!createButton.isProcessing">
                <i class="flaticon2-add-1 text-danger pr-2 icon-nm"></i>
                <span>Deneyim Oluştur</span>
              </div>
              <div class="d-flex align-items-center" v-else>
                <div class="spinner spinner-white"></div>
                <span class="ml-8">Oluşturuluyor...</span>
              </div>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-10">
            <div class="row align-items-center">
              <div class="col-md-6 my-2 my-md-0">
                <div class="input-icon">
                  <input type="text" class="form-control form-control-solid" placeholder="Ara..." v-model="searchQuery">
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <AppliedFilter :key="appliedFilterKey" @changed="appliedFilterChanged"/>
          </div>
          <div>
            <ListingTable ref="table" :filter="searchQuery" @total-rows="setTotalRows"/>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import ApiService from '@/core/services/api.service';
import ListingsFilter from "@/view/pages/experience/components/listings/Filter";
import AppliedFilter from "@/view/pages/experience/components/listings/AppliedFilter";
import ListingTable from "@/view/pages/experience/components/listings/ListingTable";

export default {
  components: {
    ListingsFilter,
    AppliedFilter,
    ListingTable
  },
  data() {
    return {
      searchQuery: '',
      appliedFilterKey: 0,
      totalRows: 0,
      createButton: {
        isProcessing: false
      },
    }
  },
  methods: {
    filterChanged() {
      this.appliedFilterKey += 1;
      this.$refs["table"].get();
    },
    appliedFilterChanged() {
      this.appliedFilterKey += 1;
      this.$refs["listings-filter"].init();
    },
    setTotalRows(rows) {
      this.totalRows = rows;
    },
    createExperience() {
      this.createButton.isProcessing = true;
      ApiService.post('experience/init.req.php')
      .then(({ data }) => {
        setTimeout(() => {
          this.$router.push({ name: "create-experience", params: { id: data.experienceID } });  
        }, 1000);
      })
      .catch(({ response }) => {
        setTimeout(() => {
          this.createButton.isProcessing = false;
        alert(response.data.errorMessage);
        }, 1000);
      });
    }
  }
}
</script>