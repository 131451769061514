<template>
  <div>
    <b-table
        class="table table-head-custom table-vertical-center table-borderless"
        thead-class="mb-8"
        show-empty
        empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty.svg" style="width:30%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir rezervasyon bulunamadı.</label> 
          </div>'
        stacked="lg"
        :items="experiences"
        :fields="experienceDataTableFields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-5"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(experience)="data">
          <div class="py-2 pl-0">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div class="symbol-label" :style="{ backgroundImage: `url(${data.item.image})` }"></div>
              </div>
              <div>
                <router-link :to="{ name: 'show-experience', params: { id: data.item.id }}" v-slot="{ href }">
                  <a :href="href" class="text-dark-75 font-weight-bolder text-hover-primary d-block mb-2 font-size-lg">{{ data.item.name }}</a>
                </router-link>
                <div>
                  <span class="text-muted font-weight-bold pr-2">{{ data.item.departurePoint }},</span>
                  <span class="text-muted font-weight-bold pr-2">{{ experienceConfig(`theme.${data.item.theme}.tr`) }},</span>
                  <span class="text-muted font-weight-bold">{{ experienceConfig(`serviceType.${data.item.serviceType}.tr`) }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg font-weight-bold label-inline" :class="`label-${experienceConfig(`status.${data.item.status}.color`)}`">{{ experienceConfig(`status.${data.item.status}.text`) }}</span>
        </template>
        <template #cell(rating)="data">
          <div class="d-flex flex-row align-items-center" v-if="data.item.rating > 0">
            <i class="fa fa-star mr-2 text-warning font-size-lg"></i>
            <span class="text-dark-65 font-weight-bolder font-size-h6">{{ data.item.rating }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <router-link
            :to="{ name: 'show-experience', params: { id: data.item.id }}"
            v-slot="{ href }"
          >
            <a :href="href" class="btn btn-icon btn-light btn-hover-primary btn-sm">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"/>
              </span>
            </a>
          </router-link>
        </template>
    </b-table>
    <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        class="my-0"
        size="md"
      >
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default {
  props: ["filter"],
  data() {
    return {
      isBusy: false,
      experiences: [],
      experienceDataTableFields: [
        { key: 'experience', label: 'Etkinlik', sortable: false, class: 'pl-0' },
        { key: 'rating', label: '', sortable: false },
        { key: 'status', label: '', sortable: false },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
    }
  },
  computed: {
    ...mapGetters(["experienceConfig"])
  },
  methods: {
    get() {
      this.isBusy = true;
      const storage = this.openStorage();
      let destinations = storage.destinations.values;
      let themes = storage.themes.values;
      let serviceTypes = storage.serviceTypes.values;
      let states = storage.states.values;

      if (destinations.length > 0) {
        var dParam = 'd=' + destinations.join();
      }
      if (themes.length > 0) {
        var tParam = 't=' + themes.join();
      }
      if (serviceTypes.length > 0) {
        var stParam = 'st=' + serviceTypes.join();
      }
      if (states.length > 0) {
        var sParam = 's=' + states.join();
      }
      

      ApiService.get(`experience/listings.req.php?${dParam}&${tParam}&${stParam}&${sParam}`)
      .then(({ data }) => {
        this.experiences = data.experiences;
        this.totalRows = this.experiences.length;
        setTimeout(() => {
          this.isBusy = false;  
          this.$emit('total-rows', this.totalRows);
        }, 500);
      })
      .catch(() => {
        this.experiences = [];
        this.totalRows = 0;
        setTimeout(() => {
          this.isBusy = false;  
        }, 500);
      });
    },
    openStorage () {
      return JSON.parse(sessionStorage.getItem('experience-listings-filter'));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1
    },

  }
}
</script>

<style>

</style>