<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Filtrele
          <span class="d-block text-muted pt-2 font-size-sm"></span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Tema</div>
        <div class="checkbox-list">
          <label v-for="item in themes" :key="item.value" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.value" v-model="checkedThemes"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Hizmet Tipi</div>
        <div class="checkbox-list">
          <label v-for="item in serviceTypes" :key="item.value" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.value" v-model="checkedServiceTypes"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Hareket Yeri</div>
        <div class="checkbox-list">
          <label v-for="item in destinations" :key="item.id" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.id" v-model="checkedDestinations"/>
            <span></span>
            {{ item.name.tr }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Durum</div>
        <div class="checkbox-list">
          <label v-for="item in states" :key="item.value" class="checkbox checkbox-pill font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light" :class="`checkbox-light-${item.color}`">
            <input type="checkbox" :value="item.value" v-model="checkedStates"/>
            <span style="color: red"></span>
            {{ item.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      destinations: [],
      checkedDestinations: [],
      checkedThemes: [],
      checkedServiceTypes: [],
      checkedStates: [],
      timeout: null
    }
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    themes() {
      let arr = [];
      Object.entries(this.experienceConfig(`theme`)).forEach(([key, value]) => {
        arr.push({
          value: parseInt(key),
          name: value.tr
        });
      });
      return arr;
    },

    serviceTypes() {
      let arr = [];
      Object.entries(this.experienceConfig(`serviceType`)).forEach(([key, value]) => {
        arr.push({
          value: parseInt(key),
          name: value.tr
        });
      });
      return arr;
    },

    states() {
      let arr = [];
      Object.entries(this.experienceConfig(`status`)).forEach(([key, value]) => {
        arr.push({
          value: parseInt(key),
          name: value.text,
          color: value.color
        });
      });
      return arr;
    }
  },
  watch: {
    checkedDestinations(val) {
      let arr = [];
      val.forEach((el) => {
        this.destinations.find((destination) => {
          if (destination.id === el) {
            arr.push({value: el, name: destination.name.tr});
          }
        })
      })
      this.updateFilter('destinations', {groupName: 'Hareket Yeri', values: val, items: arr});
    },
    checkedThemes(val) {
      let arr = [];
      val.forEach((el) => {
        this.themes.find((theme) => {
          if (theme.value === el) {
            arr.push({value: el, name: theme.name});
          }
        })
      })
      this.updateFilter('themes', {groupName: 'Tema', values: val, items: arr});
    },
    checkedServiceTypes(val) {
      let arr = [];
      val.forEach((el) => {
        this.serviceTypes.find((serviceType) => {
          if (serviceType.value === el) {
            arr.push({value: el, name: serviceType.name});
          }
        })
      })
      this.updateFilter('serviceTypes', {groupName: 'Hizmet Tipi', values: val, items: arr});
    },
    checkedStates(val) {
      let arr = [];
      val.forEach((el) => {
        this.states.find((state) => {
          if (state.value === el) {
            arr.push({value: el, name: state.name});
          }
        })
      })
      this.updateFilter('states', {groupName: 'Durum', values: val, items: arr});
    }
  },
  mounted() {
    this.getDestinations();
  },
  methods: {
    init() {
      this.isInit = true;
      const storage = this.openStorage();
      if (!storage) {
        this.checkedDestinations = [];
        this.checkedThemes = [];
        this.checkedServiceTypes = [];
        this.checkedStates = [];
      } else {
        this.checkedDestinations = storage.destinations.values;
        this.checkedThemes = storage.themes.values;
        this.checkedServiceTypes = storage.serviceTypes.values;
        this.checkedStates = storage.states.values;
      }
    },
    getDestinations() {
      ApiService.get(`destination/listing/has-experience/get.req.php`)
      .then(({ data }) => {
        this.destinations = data.destinations;
        this.init();
      })
      .catch(() => {
        this.destinations = [];
        this.init();
      });
    },
    openStorage () {
      return JSON.parse(sessionStorage.getItem('experience-listings-filter'));
    },
    saveStorage (form) {
      sessionStorage.setItem('experience-listings-filter', JSON.stringify(form));
    },
    updateFilter (input, value) {
      let storedFilter = this.openStorage();
      if (!storedFilter) storedFilter = {};
    
      storedFilter[input] = value;
      this.saveStorage(storedFilter);

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit('changed');
      }, 750);
    }
  }
}
</script>

<style>

</style>